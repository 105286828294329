








































































































import Vue from 'vue';

export default Vue.extend({
	name: 'HelloWorld',
	props: {
		msg: {
			type: String,
			default: 'test',
		},
	},
});
